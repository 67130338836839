import React from 'react'
import { NavLink } from 'react-router-dom'

const Services = () => {
    return (
        <>
            <div class="card-header shadow-lg p-3 mb-5 bg-body-tertiary mt-5" style={{ height: "3vh" }} >
                <h2 className="fs-prospecting shdw" style={{ lineHeight: "1.6em", textAlign:"center" , color:"aqua"}}><h4><b>Sonu Sharma Sir Profile</b></h4><br />

                </h2>
            </div>

            <div className='rowside' style={{ display: "flex" }}> 
                <div class="p-3 mt-5 mb-5 bg-body-tertiary rounded cntr"> 
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"white" }}><h5 style={{color:"white"}}><b>Motivational Speaker, Corporate Trainer & Business Coach</b></h5><br />
                        <b> 1. </b><b style={{color:"white"}}>Name:- </b> Sonu Sharma<br /> 
                        <b> 2. </b><button className='primary'><NavLink to="/ssmedia" style={{ backgroundColor: "blue" , color:"white" }}> <b>...Click Here...</b></NavLink></button> <br />
                    </h2> 
                </div> 
                
                <hr />
            </div>
 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{height: "3vh" , backgroundColor: ""}} >
                <h2 className="fs-prospecting shdw" style={{ lineHeight: "1.6em" , textAlign:"center",color:"aqua" }}><h4><b>Chandan Rana Profile</b></h4><br />

                </h2> 
            </div> 
 
            <div className='rowside' style={{ display: "flex" }}> 
                <div class="p-3 mt-5 mb-5 bg-body-tertiary rounded cntr"> 
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"white"  }}><h5 style={{color:"white"}}><b>Web Developer By Profession , Expert Networker By Choice</b></h5><br />
                        <b> 1. </b><b >Name:- </b> Chandan Rana<br />
                        <b> 2. </b><button className='primary'><NavLink to="/CRmedia" style={{ backgroundColor: "blue",color:"white"}}> <b>...Click Here...</b></NavLink></button> <br />
                    </h2>
                </div>
              
                <hr /> 
            </div> 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{height: "3vh",backgroundColor: ""}} > 
                <h2 className="fs-prospecting shdw" style={{ lineHeight: "1.6em", textAlign:"center"  ,color:"aqua" }}><h4><b> Only Chandan's Team Profile</b></h4><br />

                </h2>
            </div>


            <div className='rowside' style={{ display: "flex" }}>
                <div class="p-3 mt-5 mb-5 bg-body-tertiary rounded cntr">
                    <h2 className="fs-services" style={{ lineHeight: "1.6em", textAlign:"center" ,color:"white"  }}><h5 style={{color:"white"}}><b>Dynamic Personality & Leading Peoples</b></h5><br />
                        <b> 1. </b><b>Name:- </b> Leading Leaders<br />
                        <b> 2. </b><button className='primary'><NavLink to="/Teammedia" style={{ backgroundColor: "blue",color:"white"}}> <b>...Click Here...</b></NavLink></button> <br />
                    </h2>
                </div>
              
                <hr />
            </div>
        </>
    )
}

export default Services     