import React, { useContext, useEffect } from 'react'
import noteContext from '../context/notes/noteContext';
import { useNavigate } from 'react-router-dom'


const About = () => {
    const context = useContext(noteContext);
    const { getNotes } = context;


    let navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        } 
        else {
            navigate("/login");
        } 
 
    }, []) 




    // const a = useContext(noteContext)
    // useEffect(() => {
    //     a.update()
    //     // eslint-disable-next-line
    // }, [])
    //     <div>
    //     This is about { a.state.name } And He is In Class { a.state.class }

    //     </div >    



    return (
        <div>
            This is about page

        </div>
    )
}

export default About