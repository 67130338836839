import React from 'react'
import { NavLink } from 'react-router-dom'

const Master = () => {
    return (
        <div style={{color:"white"}}>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h2><b>3 Major Skills Sikhne Ke Baad Kya Kare </b></h2><br />
                    <b> 1. </b>Prospecting Kijiye Aur Usi Time Invitaion<br />  
                    <b> 2. </b>Send a Two Min Video , Before Presentation<br /> 
                </h2>
            </div> 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://youtu.be/2xD5xFKREYw?si=sSSDWIwSX7NviZq4" style={{ color: "" }}> <b>If Girl , Then Send-- Video 1 , </b></NavLink><br />
                <NavLink to="https://youtu.be/xnFecZ95j8s?si=s7XUKE5HN2NugF7q" style={{ color: "" }}> <b>Video 2</b></NavLink> 
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://youtu.be/h6xBYeK6w6I?si=0O8L5DjAzLII2s_P" style={{ color: "" }}> <b>If Boy , Then Send-- Video 1, </b></NavLink> <br />
                <NavLink to="" style={{ color: "" }}> <b>Video 2</b></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h5><b>3. Presentation Attend Krwane Se Pehle Zoom App Ka Video Send (Contact Your Upline For Video)</b></h5><br />
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h5><b>4. After Webinar- Ask , Pure Webinar Me Sabse Badhiya Kya Laga Aapko</b></h5><br />
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h5><b>5. Edification Kijiye , Jinse Baat Karwana Chahte Hai</b></h5><br /> 
                </h2> 
            </div> 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" > 
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h5><b>6. Proper Time Fix Kijiye Baat Karwane Ka & Send This , 
                    <NavLink to="/Namelist" style={{ color: "black" }}> <b>Video Link</b></NavLink> 
                </b></h5><br /> 
                </h2> 
            </div> 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h5><b>7. Proper Baat Karwane Ke Baad , Follow Up Achhe Se Kijiye , Video Send Karke Bhi - 
                    <NavLink to="/Namelist" style={{ color: "black" }}> <b>Video Link</b></NavLink> 
                </b></h5><br />
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h5><b>8. Closing Ek Process Hai , Agar Payment Na Aaye To Upline Se Phir Ek Baar Time Lekar Baat Karwaiye</b></h5><br />
                </h2>
            </div> 

        </div>
    )
}

export default Master 