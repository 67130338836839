import React from 'react'
import { NavLink } from 'react-router-dom'
import "./css/media.css"

const ssmedia = () => {
  return (
    <div>
        <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{ height: "11vh" }} >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em" }}><h3><b>Sonu Sharma Sir Social Media</b></h3><br />

                </h2>
            </div>

            <div className='rowside' style={{ display: "flex" }}>
                <div class="boxShadow p-3 mt-5 mb-5 bg-body-tertiary rounded">
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"white" }}><h4 style={{color:"aqua"}}><b>Insta Id</b></h4><br />
                        <b> 1. </b><b >Insta Id </b>- officesonusharma<br /> 
                        <b> 2. </b><button className='primary'><NavLink to="https://www.instagram.com/officesonusharma/" style={{ backgroundColor: "blue" , color:"white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div> 
                <div class="boxShadow p-3 mt-5 mb-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"white"  }}><h4 style={{color:"aqua"}}><b>YouTube Id</b></h4><br />
                        <b> 1. </b><b>YouTube Id </b>- SONUSHARMAMotivation<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.youtube.com/@SONUSHARMAMotivation" style={{ backgroundColor: "blue",color:"white" }}> <b>YouTube Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div> 
                <div class="boxShadow p-3 mb-5 mt-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em",color:"white"  }}><h4 style={{color:"aqua"}}><b>Facebook Id</b></h4><br />
                        <b> 1. </b><b>Facebook Id </b>- Sonu Sharma<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.facebook.com/profile.php?id=100034922888706" style={{ backgroundColor: "blue",color:"white" }}> <b>FB Link , click Here</b></NavLink></button> <br />
                    </h2> 
                </div>
                <hr />
            </div>

    </div>
  )
}

export default ssmedia