import React from 'react'
import plan1 from "./images/plan1.jpg"
import plan2 from "./images/plan2.jpg"

const Invitaion = () => {
    return (
        <div>
           
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{flexDirection:"column"}} >
                <h2 style={{ lineHeight: "2em" , color:"white" }}> <b>Right Way to Show the Plan</b><br />
                </h2>
                <img src={plan1} class="img-fluid img-offlineplan" alt="online"></img>
                <img src={plan2} class="img-fluid img-offlineplan" alt="online"></img>
            </div>
        </div>
    ) 
}

export default Invitaion