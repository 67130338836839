import React from 'react'
import p1 from "../images/p1.jpg"
import p2 from "../images/p2.jpg"
import "../css/work.css"
import { NavLink } from 'react-router-dom'

const Prospecting = () => {
    return (
        <div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ color: "white", lineHeight: "1.6em" }}><h1 style={{ color: "skyblue" }}><b>Prospecting like a Pro+ Way</b></h1><br />
                    <b> 1. </b>आप अपने बारे में बताइए , Introduction
                    <br />
                    <b> 2. </b>क्या नाम है आपका
                    <br />
                    <b> 3. </b>कहां के रहने वाले जो आप
                    <br />
                    <b> 4. </b>क्या करते हो आप अभी
                    <br />
                    <b>5. </b>Sonu सर को कब से जानते हो आप<br />
                    <b>6. </b>अगर मौका मिले उनके साथ करने का तो कितना समय दे पाओगे आप <br />
                    <b>7. </b>आपने कभी इससे पहले ऑनलाइन काम किए हो  क्या !
                    <br />
                    <b>8. </b>कितना समय दे पाएंगे आप इसमें । <br />
                    <b>9. </b>ओके, कॉल पे सारा कुछ बताना पॉसिबल नहीं है<br />
                    <b>10. </b>किसी भी काम के बारे में
                    <br />
                </h2>
            </div>
            <hr style={{backgroundColor:"white"}}/>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-prospecting" >
                <h2 style={{ lineHeight: "2em", color: "white" }}> <b>Written Page Prospecting</b><br />
                </h2>
                <img src={p1} class="img-fluid img-prospecting" alt="online"></img>
                {/* <img src={p2} class="img-fluid img-prospecting" alt="online"></img> */}
            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-prospecting"><h3 style={{ color: "white" }}>Live Prospecting:-</h3>
                <NavLink to="https://youtu.be/Gr5lt8zqvME?si=o52CHLkcbFhlewC_" style={{
                    boxShadow: "white 3px 5px 9px",
                    height: "6vh",
                    width: "72%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}> <h5><b> Video Link 1 </b></h5></NavLink> <br /><br />
                <NavLink to="https://youtu.be/TPSPCDQMP8g?si=jh6n42aDntj6sZPD" style={{
                    boxShadow: "white 3px 5px 9px",
                    height: "6vh",
                    width: "72%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}> <h5><b>Video Link 2</b></h5></NavLink>

            </div>
            <hr style={{backgroundColor:"white"}}/>
            
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ color: "white" }}>
                <h4><b> All The Very Best to You From My Side</b></h4>
            </div>

        </div>
    )
}

export default Prospecting