import "../components/css/product.css"
// import { a } from 'react-router-dom'
import logo3 from "./images/logo3.jpg"
import doctor from "./images/doctor.webp"
import fit from "./images/fit.jpeg"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import { NavLink } from "react-router-dom"
import calcium from "./images/calcium.jpeg"
import ganoderma from "./images/ganoderma.jpeg"
import sea from "./images/sea.jpeg"
import hairskin from "./images/hairskin.jpeg"
import larginine from "./images/larginine.jpeg"

// import Ganoderma1 from "../images/ganoderma1.jpg"

const Product = () => {
    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])


    return (
        <>
            <div className='container-product'> 
                <img src={doctor} alt='doctor' /> 

            </div>
            <div className='box-product'>
                <div className='supplement'>
                    <h1 style={{ marginTop: "0.3em", color: "black", padding: "0.5em" }}><h3><i>  Difference Between Supplement or Medicine</i></h3></h1>
                    <b style={{ fontSize: "1.6em", marginTop: "0.5em" }}>Supplement</b>
                    <p className="w-wrap"> <h1><b>***</b></h1> Supplement is like a Food , Which id Full-filled With Vitamnins & Minerals That Takes Our Body And Maintain Health , Supplement Has No Side-Effects.... </p>
                </div>
                <div className='supplement'>
                    <b style={{ fontSize: "1.6em" }}>Medicine</b>
                    <p className="w-wrap"> <h1><b>**</b></h1>  Medicine Is a Type Of Pure Chemical Building Block , Medicine Has Side-Effect  </p>
                </div>
            </div>

            <div className="bx"  > 

                <h1 style={{ marginBottom: '2em', color:"white" , textAlign: "center" }}>Best Health Supplement Knowledge</h1>
                <form className="d-flex">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                </form>

                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }} >
                    <div className="box1-product">
                        <a href='#calcium' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex", 
                                flexDirection: "column",
                                justifyContent: "center", 
                                alignItems: "center"
                            }}> 
                                <figure className="figure">
                                    <img src={calcium} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Calcium</figcaption></h6>
                                </figure> 
                            </div> 
                        </a> 
                    </div>
                    <div className="box1-product" >
                        <a href='#glucosamine' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Glucosamine</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#noni' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Noni</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#flax-oil' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Flax Seed Oil</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>

                </div>
                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }} >

                    <div className="box1-product" >
                        <a href='#noni' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">CMD</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Co-Enzyme Q10</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#ganoderma' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={ganoderma} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Ganoderma</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Spirullina</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }} >
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Colostrum</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#seabuckthorn' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={sea} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Sea-Buck-Thorn</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#larginine' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={larginine} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">L - Arginine</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Protein Powder</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }}>
                    <div className="box1-product" >
                        <a href='#hairskin' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={hairskin} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Hair Skin & Nail</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Satavari</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">GlucoHaelth</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="fitness" />
                                    <h6> <figcaption className="figure-caption text-center mt-2 big">Sublingual Spray Vitamin-D</figcaption></h6>
                                </figure>
                            </div>
                        </a>
                    </div>
                </div ><hr/> 
            </div>


            <div className='flex' id='calcium'>
                <div className='mid-product'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={calcium} alt='fitness' />
                        <img src={calcium} alt='fitness' />
                        <img src={calcium} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={calcium} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Calcium</h2>
                    <p>\_________ Calcium Is The Most Important Element In Our Body , Essential Nutrients For Bone Health To Achieve Peak Bone Mass ...
                    </p>
                    <p><b>Why Is Important To Have Healthy Bones :-</b></p>
                    <p>(1.) First, Most Calcium Found In Our Teeth & Bones <br />
                        (2.)Our Bones Support us & Allow us To Move... <br />(2.)Bones Store Minerals Such As Calcium & Phosphorous Which Help Bones Strong... <br />
                    </p>
                    <p className='product-data-price'>
                        <b> Why Calcium Most Important For You :- </b>
                    </p>

                    <p>(1.) Builds And Maintain Healthy & Strong Bones <br />
                        (2.) Muscle Contraction<br />
                        (3.) Secretion Of Harmones & Enzymes<br />
                        (4.) Sending Message To Nervous System<br />
                        (5.) Improves Muscle Function & Strength<br />
                        (6.) Builds & Support Immunity<br />  
                        (7.) Reduces Risk Of Heart Disease<br />
                    </p>
                    <p className='product-data-price'>
                        <b>Note :- Market Ke Calcium Supplement Occasionally Kidney Stone Ka Problem Create Karte h , Lekin Vestige Calcium Supplement Acche Se Digest Ho Jaate Hai... </b>
                    </p>


                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                    </div>

                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                    </div>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />
            

              


            <div className='flex' id='ganoderma'>
                <div className='mid-product'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={ganoderma} alt='fitness' />
                        <img src={ganoderma} alt='fitness' />
                        <img src={ganoderma} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={ganoderma} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Ganoderma ( King of Herbs )</h2>
                    <p>\_________ Gano -- चमकदार
                        & derma -- skin
                    </p>
                    <p><b>** Works by 3 Way :-</b></p>
                    <p>(1.) Scanning the body , And Work on it -- Like Antivirus <br />
                        (2.) Detoxification -- Cell Work Level & Clean Cell & Make Healthy <br />(3.) Immunity Booster -- सही पोषण लेने में help करता हैं
                        <br />
                    </p>
                    <p className='product-data-price'>
                        <b>* Health Benefits of Ganoderma :- </b>
                    </p>

                    <p>(1.) For Brain Health <br />
                        (2.) For Immune System<br />
                        (3.) For Cancer Treatment<br />
                        (4.) For Heart Health <br />
                        (5.) For Diebetes<br />
                        (6.) For Weight Loss<br />
                        (7.) For Digestive System<br />
                        (8.) For Over-all Health<br />
                    </p>
                    <p className='product-data-price'>
                        <b>*** No Side-Effect at All </b>
                    </p>

                    <div className='view'>
                        <button><NavLink to="https://drive.google.com/file/d/1U-ritFLM8ierInm1f3QtbFOcIEoGIEVo/view?usp=drive_link" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                    </div>
                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                    </div>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />

            <div className='flex' id='larginine'>
                <div className='mid-product'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={larginine} alt='fitness' />
                        <img src={larginine} alt='fitness' />
                        <img src={larginine} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={larginine} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>L - Arginine </h2>
                    <p>\_________ This Is Chemical Building Block , Jise Amino Acid Bhi Kaha Jaata Hai...
                    </p>
                    <p><b>** Health Benefits of L - Arginine :-</b></p>
                    <p>(1.) Heart Aur Blood Artery (Dhamniya) Ko Healthy Rakhta Hai <br />
                        (2.) High BP & Colestrol Ko Control Karta Hai <br />(3.) Badhti Age & Galat Khan-Paan Se Nijaat Dilata Hai
                        <br /> 
                    </p>
                    <p className='product-data-price'>
                        <b>* More Health Benefits of L -Arginine :- </b>
                    </p>

                    <p>(1.) Veins Ka Sikudna Aur Tight Ho Jaana <br />
                        (2.) Sexual System Ko Best Karna<br />
                        (3.) Badhti Age Ka Effect Kam Karna<br />
                        (4.) Blood Sugar Ko Control Karna <br />
                        (5.) Stamina Ko Improve Karna<br />
                        
                    </p>
                    <p className='product-data-price'>
                        <b>Note:- Ye Veins Ko Open Kar Deta Hai , Jisse Blood Ka Flow Achhi Trah Se Ho Sake </b>
                    </p>
                    <p className='product-data-price'>
                        <b>Usage:- One Packet 200 ML Water / Juice Ke Saath Le Sakte Hai </b>
                    </p>

                    <div className='view'>
                        <button><NavLink to="https://drive.google.com/file/d/1U-ritFLM8ierInm1f3QtbFOcIEoGIEVo/view?usp=drive_link" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                    </div>
                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                    </div>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />

            <div className='flex' id='seabuckthorn'>
                <div className='mid-product'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={sea} alt='fitness' />
                        <img src={sea} alt='fitness' />
                        <img src={sea} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={sea} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Sea - Buckthorn</h2>
                    <p>\_________ Called as Himalyan Berry , Iska Root 200 ft Niche Hota hai , Plants Me Sabse Uch Sthan Diya Gaya Hai...
                    </p>
                    <p><b>** Why Should We Take :-</b></p>
                    <p>(1.) Astronaut Ko Sea - Buckthorn Diya Jata Hai , Taki Energy Ki Kami Na Ho |<br />
                        (2.) Ye , Anti Aging ,, Anti Cancer & Anti Disease H , Jise Vitamins Aur Minerals KA Bhandaar Kaha Jata Hai <br />(3.) Isme<br/>..... Orange Se 12 X Jayda -- Vitamin C <br/> .....Caarot Se 3 X Jayada -- Vitamin A  Milta hai<br/>
                        <br /> 
                    </p>
                    <p className='product-data-price'>
                        <b>* More Health Benefits of Sea - Buckthorn :- </b>
                    </p>

                    <p>(1.) More Anti-Oxidants <br/>.......<b>\___Ye Ladte Hai Free Radical Se & Save Us </b> <br />
                        (2.) Maintain High Energy<br />
                        (3.) Reduce Head-ache , Dizinessn & Increases Mental Attention<br />
                        (4.) Stamina Building <br />
                        (5.) Cardiac And Diabetic Benefits   <br />
                        (5.) Skin Benefits & Gastric Benefits  <br />
                        (5.) Best Benefit In Liver    <br />
                        
                    </p>

                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                    </div>
                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                    </div>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />

            <div className='flex' id='hairskin'>
                <div className='mid-product'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={hairskin} alt='fitness' />
                        <img src={hairskin} alt='fitness' />
                        <img src={hairskin} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={hairskin} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Hair Skin & Nail Supplement</h2>
                    <p>\_________ Mainly , It is Build For Our Hair , Skin & Nail
                    </p>
                    <p><b>** Capsules Contain :-</b></p>
                    <p>(1.) Green Tea Extract<br />
                        (2.) Borage Oil <br />(3.) Amino Acid -- (Lysine , Methionine Cysteine)<br/>(4.) Vitamins (E , B6 , Folic Acid)
                        <br /> (5.) Minerals (Mg , Zn , Se , Biotin , Iron) <br /> 
                    </p>
                    <p className='product-data-price'>
                        <b>* Health Benefits of Hair Skin & Nail :- </b>
                    </p>

                    <p>(1.) Ye Hair , Skin & Nail Ko Nourish Karta Hai <br/>
                        (2.) Ye Nails Ko Thickness Aur Strong Karta Hai<br />
                        (3.) Helps Retain Skin Moisture & Prevent Skin Inflamation<br />
                        (4.) Helps To Delat the Signs of Aging <br />
                        (5.) Helps in Promoting Hair Growth   <br />
                        (5.) Helps in Maintaing the Volume of Hair  <br />
                        (5.) Maintain Over All Hair ,Skin & Nail    <br />
                        
                    </p>

                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                    </div>
                    <div className='view'>
                        <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                    </div>

                </div>
            </div> <hr style={{ marginTop: "5em" }}/> 

        </>
    )
}

export default Product