import React from 'react'
import { NavLink } from 'react-router-dom'
import  "../css/work.css"
import offlineprospecting from "../images/offlineprospecting.jpg"

const Namelist = () => {
    return (
        <div>
            <div>
                <p class="fw-semibold fs"><h3><b>Create Your Name List</b></h3></p>
            </div>
            <table class="table table-dark table-striped mt-4"> 
                <thead>
                    <tr> 

                        <th scope="col">Name</th>
                        <th scope="col">Contact No.</th>
                        <th scope="col">Location</th>
                        <th scope="col">Profession</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>

                        <td>Chandan</td>
                        <td>7324837251</td>
                        <td>Delhi</td>
                        <td>Work</td>
                    </tr>
                    <tr>

                        <td>Sarita</td>
                        <td>9838847363</td>
                        <td>Up</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">Rekha</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">kaushalya</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">khushbu</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">nitish</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">haridwar</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">Nisha</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">Roshni</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                    <tr>
                        <td colspan="2">Mukesh</td>
                        <td>Faridabad</td>
                        <td>Work</td>
                    </tr>
                </tbody>



            </table>

            <div style={{textAlign:"center"}}>
                <p class="fw-semibold mt-5 fs" ><h3><b>Prospecting on Your List</b></h3></p>
                <img src={offlineprospecting} class="img-fluid img-prospecting" alt="online"></img>
            </div>

            <div>
                <p class="fw-semibold mt-5 fs"><h3><b>Create 5 Star Hot List <br/></b></h3></p>
            </div>

            <div>
                <table class="table table-dark table-striped mt-5">
                    <thead>
                        <tr>

                            <th scope="col">Name</th>
                            <th scope="col">Contact No.</th>
                            <th scope="col">Location</th>
                            <th scope="col">5 Star Rating <br/>(1,2,3,4,5)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                            <td>Chandan</td>
                            <td>7324837251</td>
                            <td>Delhi</td>
                            <td>5</td>
                        </tr>
                        <tr>

                            <td>kaushalya</td>
                            <td>7324837251</td>
                            <td>Delhi</td>
                            <td>5</td>
                        </tr>
                        <tr>

                            <td>khushbu</td>
                            <td>7324837251</td>
                            <td>Delhi</td>
                            <td>4</td>
                        </tr>
                        <tr>

                            <td>haridwar</td>
                            <td>7324837251</td>
                            <td>Delhi</td>
                            <td>4</td>
                        </tr>
                        <tr>

                            <td>nisha</td>
                            <td>7324837251</td>
                            <td>Delhi</td>
                            <td>3</td>
                        </tr>

                    </tbody>

                </table>
            </div>


        </div >
    )
}

export default Namelist