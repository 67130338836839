import React from 'react'
import hostingpage1 from "./images/hostingpage1.jpg"
import hostingpage2 from "./images/hostingpage2.jpg"

const Invitaion = () => {
    return (
        <div>
           
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{flexDirection:"column"}} >
                <h2 style={{ lineHeight: "2em" , color:"white" }}> <b>Right Way to Host a Meeting</b><br />
                </h2>
                <img src={hostingpage1} class="img-fluid img-offlineplan" alt="online"></img>
                <img src={hostingpage2} class="img-fluid img-offlineplan" alt="online"></img>
            </div>
        </div>
    ) 
}

export default Invitaion