import React from 'react'

const Dreamlist = () => {
    return (
        <div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <h3><b>* Three Types of Dreams</b></h3>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <h4><b>1. Financial Dream</b></h4>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
               <h4> <b>2. Materialistic Dream</b></h4>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
               <h4> <b>3. Personal Dream</b></h4>
            </div>
            <hr/>
            <p class="fw-semibold mt-5 fs"><b style={{color:"blue"}}>Short Term Goal (6 Months) </b></p>
            <p class="fw-semibold mt-5 fs"><b style={{color:"black"}}>Mid Term Goal (1 Year) </b></p>
            <p class="fw-semibold mt-5 fs"><b style={{color:"green"}}>Long Term Goal (3-5 Year) </b></p>
            <hr/>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <h2> <b>Rule :- S.M.A.R.T</b></h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center" >
                <h2 style={{lineHeight:"2em"}}> <b>S - Specific</b><br />
                    <b> M </b>- Measurable<br />
                    <b> A</b> - Achievable<br />
                    <b> R</b> - Realistic<br />
                    <b>T</b> - Time - Bound
                </h2>
            </div>
            
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <h2><ul> <b>Short Term Goal</b></ul><br />
                    1. Buy Mobile Cover<br />
                    2. .................<br />
                    3. .................<br />
                    4. .................
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <h2><ul> <b>Mid Term Goal</b></ul><br />
                    1. Buy a Mobile<br />
                    2. .................<br />
                    3. .................<br />
                    4. .................
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <h2><ul> <b>Long Term Goal</b></ul><br />
                    1. Buy a Car<br />
                    2. .................<br />
                    3. .................<br />
                    4. .................
                </h2>
            </div>

        </div>
    )
}

export default Dreamlist