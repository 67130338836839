import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import team from "./images/team.jpg"
import "./css/profile.css"
import chandan from "./images/chandan.jpg"
import { NavLink } from 'react-router-dom'
import hand from "./images/hand.jpg"
import jc from "./images/jc.jpg"
import team2 from "./images/team2.jpg"
import Contact from './Contact';
// import wts from "./images/wts.jpeg"
import Services from './Services';
import team1 from "./images/team1.jpg"
import wts from "./images/wts.jpg"

const Profile = (props) => {

    // const context = useContext(noteContext);
    // let navigate = useNavigate();
    // const { getNotes } = context;
    // useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //         //   alert = "logged in";
    //         navigate("/Home");

    //     }
    //     else {
    //         // getProfile()
    //     }

    // }, [])
    // const showAlert = props
    return (
        <div>
            <div className='wts'>


                {/* <NavLink to="" style={{ color: "white" }}>  </NavLink> */}
                <NavLink to="https://wa.link/xnqghx" style={{ color: "white" }}> <img src={wts} class="" alt="..." /></NavLink>

            </div>
            <div className='fixed'>

            </div>
            <div class=" text-bg-primary mb-3" >
                <div class=""><h3 style={{ color: "white" }}>Team Chandan Rana | <br /> Team Sonu Sharma  | <br /> <br />- Mission to Create Top 20 Leadership </h3> <hr style={{ backgroundColor: "white" }} />
                </div>
                <div class="card-body">
                    <p class="card-text" style={{ alignItems: "center", color: "white", justifyContent: "center", display: "flex" }}><h6><br /><br /><b>Every-one Knows Money is Most Important But Business Provide us Time Freedom.. & I 'm Working on It.. </b></h6></p>

                </div>
            </div>

            {/* <hr style={{ backgroundColor: "white" }} /> */}


            <div class=" p-3 mb-5 bg-body-tertiary rounded center" >

                <h3 className="fs-prospecting" ><h5><b style={{ lineHeight: "1.5em", color: "white" }}>** Hum offer Karte hai , Ek Best Business jo Aap Apne Work ya Study Ke Saath Start Kar Sakte Hai<br />....................With Sonu Sharma Sir (Motivational Speaker)</b> <br /> <hr/> <b style={{ color: "#00fbff" }}> <br /> official ~ <br /> Team Sonu Sharma </b> </h5> <br />
                </h3>
            </div>

            <div class="text-bg-primary mb-3 center-hand" >
                {/* <img src={hand} class="img-fluid sticy-top img-hand" alt="..." /> */}
                <div className="fs"><h2 style={{ fontSize: "1.2em", color: "white" }}>Click on <b>Start </b>to chat</h2>
                    <NavLink to="https://wa.link/xnqghx " style={{ color: "white" }}>...Start...</NavLink>
                </div>


            </div>
            {/* <hr style={{ backgroundColor: "white" }} /> */}
            <div class="text-bg-info mt-5 mb-3">
                <div class="card-header"><h3>You 're Not Alone </h3></div><hr style={{ backgroundColor: "white" }} />
                <div class="card-body">
                    <p class="card-text "><h6 className='bada' style={{ color: "white" }}>India is Facing an Unemployment crisis And With 90% Graduates Jobless , The Lack of Skills Like Leadership & Communication is a Big Factor...Millions of Job Exist Across Industries , But Job Seekers Aren't Ready to Go </h6> </p>
                </div>
            </div>
            <div class="text-bg-info mt-5 mb-3">
                <div class="card-header"><h3>Exclusive Digital Start-up </h3></div> <hr style={{ backgroundColor: "white" }} />
                <div class="card-body">
                    <p class="card-text "><h6 className='bada' style={{ color: "white" }}>Start Your own Digital Business While Maintaining Your Current Job or Doing Your Study And Generate Income From Home .. </h6> </p>
                </div>
            </div>
            <div className='container-nb'>
                <div id="carouselExampleDark" className="carousel carousel-dark slide mt-4" data-bs-ride="carousel">
                    <div className="carousel-indicators" >
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner" style={{ boxShadow: "white -3px 4px 7px" }}>
                        <div className="carousel-item active" data-bs-interval="2000">
                            <img src={team2} className="d-block w-100 img-profile" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 style={{ color: "white" }}>First slide label</h5>
                                {/* <p style={{ color: "white" }}>Some representative placeholder content for the first slide.</p> */}
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={jc} className="d-block w-100 img-profile" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 style={{ color: "white" }}>Second slide label</h5>
                                {/* <p style={{ color: "white" }}>Some representative placeholder content for the second slide.</p> */}
                            </div>
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>

                    <div class="text-bg-info mt-5 mb-3">
                        <div class="card-header"><h3>Short Profile - Chandan Rana </h3></div>
                        <div class="card-body">
                            <p class="card-text "><h6 className='bada' style={{ color: "white" }}>Basically I Belong to Jharkhand From Hazaribag City And Started This Journey Along With My Study (BCA) , Other Students Wastes Their Time in This Golden Age (18-25) or They do Nothing But I Realize to Help People Make Money Along With Myself<br /><br /> The Question is Simple That If I Can Do , <br />Then You Can Also Definitely do , Just Believe in Yourself.. & Achieve it</h6></p>
                        </div>
                    </div>
                </div>



            </div>


            <Services />
            <Contact />
        </div>
    )
}
export default Profile