import "../components/css/product.css"
// import { a } from 'react-router-dom'
import logo3 from "./images/logo3.jpg"
import agri from "./images/agri.jpeg"
import fit from "./images/fit.jpeg" 
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';

// import Ganoderma1 from "../images/ganoderma1.jpg"

const Agri = () => {
    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])


    return (
        <>
            <div className='container-product'>
                <img src={agri} alt='Agriculture' />

            </div>
            <div className='box-product'>
                <div className='supplement'>
                    <h1 style={{ marginTop: "0.3em", color: "black", padding:"0.5em" }}><h3><i>  Difference Between Supplement or Medicine</i></h3></h1>
                    <b style={{ fontSize: "1.6em", marginTop: "0.5em" }}>Supplement</b>
                    <p className="w-wrap"> <h1><b>***</b></h1> Supplement Is Not a Type of Medicine , It Is Just Like a Food Which Takes Our Body And Maintain Health , If Supplement Is To Be Taken Then It Has No Side-Effects.... </p>
                </div>
                <div className='supplement'>
                    <b style={{ fontSize: "1.6em" }}>Medicine</b>
                    <p className="w-wrap"> <h1><b>**</b></h1>  Medicine Is a Type Of Medicine , It Is Just Like a Food Which Takes Our Body And Maintain Health , If Supplement Is To Be Taken Then It Has No Side-Effects.... </p>
                </div>
            </div>

            <div className="bx"  >

                <h1 style={{ marginBottom: '2em', textAlign: "center" }}>Best Health Supplement Knowledge</h1>
                <form className="d-flex">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                </form>

                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }} >
                    <div className="box1-product">
                        <a href='#calcium' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Calcium</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#glucosamine' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Glucosamine</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#noni' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Noni</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='#flax-oil' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Flax Seed Oil</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>

                </div>
                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }} >

                    <div className="box1-product" >
                        <a href='#noni' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">CMD</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Co-Enzyme Q10</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Ganoderma</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Spirullina</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }} >
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Colostrum</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Sea-Buck-Thorn</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Procard</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Protein Powder</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="box1-productes" style={{ display: 'flex', justifyContent: "center" }}>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Protein Choco</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Satavari</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="..." />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">GlucoHaelth</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                    <div className="box1-product" >
                        <a href='/' style={{ textDecoration: "none", color: "black" }} >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <figure className="figure">
                                    <img src={fit} className="figure-img img-fluid rounded" alt="fitness" />
                                    <h5> <figcaption className="figure-caption text-center mt-2 big">Sublingual Spray Vitamin-D</figcaption></h5>
                                </figure>
                            </div>
                        </a>
                    </div>
                </div ><hr />
            </div>


            <div className='flex' id='calcium'>
                <div className='mid'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Calcium</h2>
                    <p>\_________ Calcium Is The Most Important Element In Our Body , Essential Nutrients For Bone Health To Achieve Peak Bone Mass ...
                    </p>
                    <p><b>Why Is Important To Have Healthy Bones :-</b></p>
                    <p>(1.) First, Most Calcium Found In Our Teeth & Bones <br />
                        (2.)Our Bones Support us & Allow us To Move... <br />(2.)Bones Store Minerals Such As Calcium & Phosphorous Which Help Bones Strong... <br />
                    </p>
                    <p className='product-data-price'>
                        <b> Why Calcium Most Important For You :- </b>
                    </p>

                    <p>(1.) Builds And Maintain Healthy & Strong Bones <br />
                        (2.) Muscle Contraction<br />
                        (3.) Secretion Of Harmones & Enzymes<br />
                        (4.) Sending Message To Nervous System<br />
                        (5.) Improves Muscle Function & Strength<br />
                        (6.) Builds & Support Immunity<br />
                        (7.) Reduces Risk Of Heart Disease<br />
                    </p>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />
            <div className='flex' id='glucosamine'>
                <div className='mid'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Glucosamine</h2>
                    <p>\_________ Calcium Is The Most Important Element In Our Body , Essential Nutrients For Bone Health To Achieve Peak Bone Mass ...
                    </p>
                    <p><b>Why Is Important To Have Healthy Bones :-</b></p>
                    <p>(1.) First, Most Calcium Found In Our Teeth & Bones <br />
                        (2.)Our Bones Support us & Allow us To Move... <br />(2.)Bones Store Minerals Such As Calcium & Phosphorous Which Help Bones Strong... <br />
                    </p>
                    <p className='product-data-price'>
                        <b> Why Calcium Most Important For You :- </b>
                    </p>

                    <p>(1.) Builds And Maintain Healthy & Strong Bones <br />
                        (2.) Muscle Contraction<br />
                        (3.) Secretion Of Harmones & Enzymes<br />
                        (4.) Sending Message To Nervous System<br />
                        (5.) Improves Muscle Function & Strength<br />
                        (6.) Builds & Support Immunity<br />
                        (7.) Reduces Risk Of Heart Disease<br />
                    </p>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />
            <div className='flex' id='noni'>

                <div className='mid'>

                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                  
                </div><hr />
                
                <div className='product-data'>
                    <h2>Noni Supplement</h2>
                    <p>\_________ Calcium Is The Most Important Element In Our Body , Essential Nutrients For Bone Health To Achieve Peak Bone Mass ...
                    </p>
                    <p><b>Why Is Important To Have Healthy Bones :-</b></p>
                    <p>(1.) First, Most Calcium Found In Our Teeth & Bones <br />
                        (2.)Our Bones Support us & Allow us To Move... <br />(2.)Bones Store Minerals Such As Calcium & Phosphorous Which Help Bones Strong... <br />
                    </p>
                    <p className='product-data-price'>
                        <b> Why Calcium Most Important For You :- </b>
                    </p>

                    <p>(1.) Builds And Maintain Healthy & Strong Bones <br />
                        (2.) Muscle Contraction<br />
                        (3.) Secretion Of Harmones & Enzymes<br />
                        (4.) Sending Message To Nervous System<br />
                        (5.) Improves Muscle Function & Strength<br />
                        (6.) Builds & Support Immunity<br />
                        (7.) Reduces Risk Of Heart Disease<br />
                    </p>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />
            <div className='flex' id='flax-oil'>
                <div className='mid'>
                    <div className='product_images' style={{ marginTop: "10em", width: "min-content", lineHeight: "10px" }}>
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                        <img src={logo3} alt='fitness' />
                    </div>
                    <div className='product_images' style={{ display: "flex", marginTop: "17.75em" }}>
                        <img src={logo3} alt='fitness' />
                    </div>
                </div><hr />
                <div className='product-data'>
                    <h2>Flax-Oil</h2>
                    <p>\_________ Calcium Is The Most Important Element In Our Body , Essential Nutrients For Bone Health To Achieve Peak Bone Mass ...
                    </p>
                    <p><b>Why Is Important To Have Healthy Bones :-</b></p>
                    <p>(1.) First, Most Calcium Found In Our Teeth & Bones <br />
                        (2.)Our Bones Support us & Allow us To Move... <br />(2.)Bones Store Minerals Such As Calcium & Phosphorous Which Help Bones Strong... <br />
                    </p>
                    <p className='product-data-price'>
                        <b> Why Calcium Most Important For You :- </b>
                    </p>

                    <p>(1.) Builds And Maintain Healthy & Strong Bones <br />
                        (2.) Muscle Contraction<br />
                        (3.) Secretion Of Harmones & Enzymes<br />
                        (4.) Sending Message To Nervous System<br />
                        (5.) Improves Muscle Function & Strength<br />
                        (6.) Builds & Support Immunity<br />
                        (7.) Reduces Risk Of Heart Disease<br />
                    </p>

                </div>
            </div> <hr style={{ marginTop: "5em" }} />

        </>
    )
}

export default Agri