import React from 'react'
import { NavLink } from 'react-router-dom';

const Noteshere = () => {
  return (
    <div>
         <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                <NavLink to="https://skill.learnwithankit.in/" style={{ color: "black" }}><b>Ankit Saini Paid Course</b></NavLink>
            </div>
    </div>
  )
}

export default Noteshere