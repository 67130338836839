import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import NoteState from './context/notes/NoteState';
import Alert from './components/Alert';
import Login from './components/Login';
import Signup from './components/Signup';
import { useState } from 'react';
import Teamdata from "./components/Teamdata"
import Footer from './components/Footer';
import Product from './components/Product';
import Profile from './components/Profile';
import Online from './components/work/Online';
import Work from './components/Work';
import Warm from "./components/work/Warm"
import Namelist from './components/work/Namelist';
import Dreamlist from './components/work/Dreamlist';
import Noteshere from './components/work/Noteshere';
import Prospecting from './components/work/Prospecting';
import Invitaion from './components/work/Invitation';
import Agri from './components/Agri';
import Master from './components/work/Master';
import HealthSolution from './components/HealthSolution';
import SSmedia from './components/SSmedia';
import CRmedia from './components/CRmedia';
import Teammedia from './components/Teammedia';
import Edification from './components/work/Edification';
import Profileshow from './components/Profileshow';
import Offlineplan from "./components/Offlineplan"
import Hosting from "./components/Hosting"

function App() {
  const [alert , setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    })

    setTimeout(() => {
      setAlert(null);

    }, 2500);
  }

  return (
    <>
      <NoteState>
        <Router>
          <Navbar />
          <Alert alert={alert}/> 
          <div className="container">

            <Routes>
              <Route path='/' element={<Profile showAlert={showAlert}/>}></Route>
              <Route path='/Home' element={<Home showAlert={showAlert}/>}></Route>
              <Route exact path='/About' element={<About />}></Route>
              <Route exact path='/Login' element={<Login showAlert={showAlert} />}></Route>
              <Route exact path='/Signup' element={<Signup showAlert={showAlert}/>}></Route>
              <Route exact path='/Teamdata' element={<Teamdata showAlert={showAlert}/>}></Route> 
              <Route exact path='/Product' element={<Product showAlert={showAlert}/>}></Route> 
              <Route exact path='/Profile' element={<Profile showAlert={showAlert}/>}></Route> 
              <Route exact path='/Online' element={<Online showAlert={showAlert}/>}></Route> 
              <Route exact path='/Work' element={<Work showAlert={showAlert}/>}></Route> 
              <Route exact path='/Warm' element={<Warm showAlert={showAlert}/>}></Route>
              <Route exact path='/Namelist' element={<Namelist showAlert={showAlert}/>}></Route>
              <Route exact path='/Dreamlist' element={<Dreamlist showAlert={showAlert}/>}></Route>
              <Route exact path='/Noteshere' element={<Noteshere showAlert={showAlert}/>}></Route>
              <Route exact path='/Prospecting' element={<Prospecting showAlert={showAlert}/>}></Route>
              <Route exact path='/Invitaion' element={<Invitaion showAlert={showAlert}/>}></Route>
              <Route exact path='/Agri' element={<Agri showAlert={showAlert}/>}></Route>
              <Route exact path='/Master' element={<Master showAlert={showAlert}/>}></Route>
              <Route exact path='/HealthSolution' element={<HealthSolution showAlert={showAlert}/>}></Route>
              <Route exact path='/SSmedia' element={<SSmedia showAlert={showAlert}/>}></Route>
              <Route exact path='/CRmedia' element={<CRmedia showAlert={showAlert}/>}></Route>
              <Route exact path='/Teammedia' element={<Teammedia showAlert={showAlert}/>}></Route>
              <Route exact path='/Edification' element={<Edification showAlert={showAlert}/>}></Route>
              <Route exact path='/Profileshow' element={<Profileshow showAlert={showAlert}/>}></Route>
              <Route exact path='/Offlineplan' element={<Offlineplan showAlert={showAlert}/>}></Route>
              <Route exact path='/Hosting' element={<Hosting showAlert={showAlert}/>}></Route>
            </Routes>
          </div>
          {/* <Footer/> */}
        </Router>
      </NoteState>

    </>
  );
}

export default App;
